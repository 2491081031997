<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
      data-black-overlay="6"
      :style="{ backgroundImage: 'url(' + src + ')' }"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h1 class="heading-title">Sobre Nós</h1>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start About Area  -->
    <!-- <div class="about-area rm-about-style-2 ptb--120 bg_color--5" id="about">
        <div class="about-wrapper">
          <div class="container">
            <v-row class="row--20 align-center">
              <v-col md="6" sm="12" cols="12">
                <div class="thumbnail">
                  <img
                    class="w-100"
                    src="../assets/images/about/about-3.jpg"
                    alt="About Images"
                  />
                </div>
              </v-col>
              <v-col md="6" sm="12" cols="12">
                <div class="about-inner inner">
                  <div class="section-title">
                    <span class="subtitle">Sobre nós</span>
                    <h3 class="heading-title">Forever jobs brasil</h3>
                    <p class="description">
                      é uma empresa especializada em fornecer serviços terceirizados de qualidade.
                      <br />
                      Nós oferecemos uma ampla gama de opções de serviços, incluindo promotores de eventos, recepcionistas, garçons, bartenders, locutores, mascotes, modelos e influencers.
                      <br />
                      Possuímos uma equipe de profissionais altamente capacitados e experientes.
                      <br />
                      <br />
                      Nosso casting é selecionados com base em suas habilidades, experiência e personalidade, garantindo que eles estejam perfeitamente adequados para atender às suas necessidades.
                      <br />
                      <br />
                      Outro ponto positivo em contratar a Forever Jobs Brasil é que a nossa empresa possui logística na França. Isso significa que somos capazes de fornecer serviços de qualidade internacional.
                      <br />
                      Além disso, somos comprometidos em fornecer serviços de alta qualidade a preços competitivos.
                      <br />
                      <br />
                      Estamos sempre disponíveis para ajudá-los em todas as etapas do processo, desde a seleção do casting até o final do evento.
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
    </div> -->
    <div class="service-area ptb--120 bg_color--5">
      <v-container>
        <div class="service-white-wrapper">
          <Details />
        </div>
      </v-container>
    </div>
    <!-- Start About Area  -->

    <!-- Start Finding us Area  -->
    <div
      class="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--18"
      data-black-overlay="5"
      :style="{ backgroundImage: 'url(' + src + ')' }"
    >
      <v-container>
        <v-row>
          <v-col lg="8" offset-lg="2">
            <div class="inner">
              <div class="content-wrapper">
                <div class="text-center content">
                  <h4 class="theme-gradient">Impulsione seu negócio conosco</h4>
                  <router-link class="btn-default" to="/contact"
                    >ENTRE EM CONTATO</router-link
                  >
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Finding us Area  -->

    <!-- Start team Area  -->
    <!-- <div class="rn-team-area bg_color--1 ptb--120">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="text-center section-title mb--25">
              <h4 class="heading-title colored-title ">Conheça nossa equipe qualificada</h4>
            </div>
          </v-col>
        </v-row>
        <Team />
      </v-container>
    </div> -->
    <!-- Start team Area  -->
    <Footer />
  </div>
</template>

<script>
  import Header from "../components/Header";
  import About from "../components/About";
  // import Team from "../components/about/Team";
  import Footer from "../components/Footer";
  import Details from "../components/about/Details";
  export default {
    components: {
      Header,
      About,
      // Team,
      Footer,
      Details,
    },
    data() {
      return {
        logo: require("../assets/images/logo-sm.png"),
        src: require("../assets/images/about/data.jpeg"),
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "Sobre nós",
            to: "",
            disabled: true,
          },
        ],
      };
    },
  };
</script>
<style>
  .colored-title {
    background: linear-gradient(120deg, #1C99FE 20.69%, #7644FF 50.19%, #FD4766 79.69%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    display: inline-block !important;
  }
  .heading-title {
    color: white !important;
  }
  </style>
